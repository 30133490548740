p, button {
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

.address_box {
  margin-bottom: 20px;

  @media (min-width: 768px) {
    margin-bottom: 28px;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(174, 174, 174, 0.3); /* Затемнення фону з невеликим альфа-каналом */
  z-index: 1000; /* Забезпечуємо, щоб перекривало всі інші елементи */
  display: flex;
  justify-content: center;
  align-items: center;
}

/* Стилі для лоадера */
.loader {
  border: 2px solid rgba(0, 0, 0, 0.1);
  border-radius: 50%;
  border-top: 2px solid #ef6f22; /* Колір лоадера */
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

/* Анімація обертання лоадера */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.input_page_container {
  background-color: #ffffff;
  border-radius: 10px;
  padding: 40px;
  box-sizing: border-box;

  @media (min-width: 768px) {
    width: 715px;
  }

  h2 {
    font-family: "Roboto";
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #303134;
    margin-bottom: 20px;

    @media (min-width: 768px) {
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;

      margin-bottom: 36px;
    }
  }

  h4 {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #707479;
    margin: 0;
  }

  .radio_group_container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  .input_label {
    display: flex;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    color: #303134;
    margin-bottom: 14px;
    margin-top: 30px;

    @media (min-width: 768px) {
      font-weight: 500;
      font-size: 16px;
    }
  }

  .buildingtype_container {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }

  .buildingtype_item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;
  }

  .buildingtype_item_radio {
    display: flex;
    margin: 0;
    width: 136px;
    justify-content: center;
    margin-top: 23px;
  }

  .button_container {
    display: flex;
    flex-direction: column;
    align-items: center;

    &Content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 10px;
      width: 100%;

      @media (min-width: 768px) {
        margin-bottom: 36px;
      }
    }

    @media (min-width: 768px) {
      margin-bottom: 12px;
    }

    button {
      background: #ef6f22;
      border-radius: 4px;
      border: none !important;
      font-family: "Roboto";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      color: #ffffff;
      padding: 14px 42px;
      cursor: pointer;
      width: 100%;
      min-width: 215px;
      outline: none !important;

      @media (min-width: 768px) {
        width: auto;
      }

      &:hover {
        opacity: 0.8;
      }
      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

.policy {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  color: #717780;
  text-align: center;
  line-height: 22px;
  max-width: 350px;

  @media (min-width: 768px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }
}

.policyBold {
  font-weight: 500;
  color: #717780;
  font-size: 14px;
  line-height: 22px;

  @media (min-width: 768px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
  }
}

@media screen and (max-width: 767px) {
  .input_page_container {
    padding: 30px 20px 30px 20px;

    h2 {
      font-size: 20px;
    }

    .buildingtype_item {
      img {
        height: 70px;
      }
    }

    .button_container {
      button {
        font-size: 14px;
      }

      span {
        font-size: 14px;
      }
    }
  }
}

@media screen and (max-width: 450px) {
  .input_page_container {
    padding: 30px 16px 30px 16px;

    .button_container {
      button {
      }
    }
  }
}

.propertiesSection {
  // display: grid;
  // grid-template-columns: auto auto;
  // justify-content: space-between;
  // gap: 28px 0px; // 28px - вертикальний відступ, 0px - горизонтальний

  display: grid;
  grid-template-columns: repeat(1, 1fr);
  justify-content: space-between;
  flex-direction: column;
  gap: 20px;
  margin-bottom: 20px;

  margin-bottom: 20px;

  @media (min-width: 768px) {
    grid-template-columns: auto auto;
    justify-content: space-between;
    margin-bottom: 36px;
    gap: 28px 0px; // Залишаємо вертикальний відступ і прибираємо горизонтальний
  }

  @media print {
    grid-template-columns: auto auto;
  }
}

@media print {
  .properties-section {
    grid-template-columns: repeat(2, 1fr);
  }
}

.sectionType {
  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 22px;
    color: #2d3035;

    @media (min-width: 768px) {
      font-size: 16px;
      font-weight: 500;
      line-height: 25px;
      color: #2d3035;
    }
  }
}

.btnClose {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 30px;
  right: 0px;
  cursor: pointer;
}
.btnClose:after {
  content: "";
  height: 20px;
  border-left: 2px solid #303134;
  position: absolute;
  transform: rotate(45deg);
}

.btnClose:before {
  content: "";
  height: 20px;
  border-left: 2px solid #303134;
  position: absolute;
  transform: rotate(-45deg);
}

.buttonload {
  display: flex;
  width: 100%;
  justify-content: center;
  align-self: center;
  gap: 10px;
}

.spinner {
  display: inline-block;
  width: 14px;
  height: 14px;
  border: 2px solid white;
  border-top: 2px solid #d95d1a;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.loaderBox {
  display: flex;
  gap: 10px;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.error {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  text-align: center;
  color: #f5a16f;
  margin: 0;
  margin-top: 10px;
}