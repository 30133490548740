.emailInput {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #b3bcc9;
  padding: 10px 14px 9px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #2d3035;
}

.addressLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  @media (min-width: 768px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #303134;
  }

  margin-bottom: 10px;
}

.error_message {
  padding-right: 20px;
  color: rgb(211, 47, 47);
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 10px;
}
