.footer_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 12px;

  .footer_text {
    font-family: "Roboto";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #2D3035;
  }
}

.footer_container_flex {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 7px;
}

.footer_date {
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
  color: #717780;
}