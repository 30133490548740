.addressContainer {
  //display: ;
}

.inputContainer {
  border-radius: 4px;
  border: 1px solid #b3bcc9;
  padding: 10px 14px 9px;
  margin: 0;
  font-size: 14px;
  font-weight: 400;
  color: #2d3035;
}

.addressLabel {
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;

  @media (min-width: 768px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
    color: #303134;
  }

  //margin-bottom: 10px;
}

.autocomplete {
  &Mobile {
    &Container {
      display: none;
      margin-bottom: 10px;
    }
  }
}

.formRequestInputContainerGap {
  margin-bottom: -28px;
}
.autocomplete {
  &Mobile {
    &Container {
      display: flex;
      justify-content: space-between;
    }
    &Status {
      font-size: 14px;
      font-weight: 400;
      line-height: 16px;
      letter-spacing: 0em;
    }
    &Btn {
      display: flex;
      align-items: center;
      column-gap: 4px;
      border: none;
      background: none;
      font-family: "Roboto";
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0em;
      text-align: right;
      color: #196faf;
      transition: opacity 0.3s;
      cursor: pointer;
      &:hover {
        opacity: 0.7;
      }
      &:disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }
    }
  }
}

.loadingText {
  color: rgb(25, 111, 175);
  font-size: 12px; 
}

.errorText {
  color: #d32f2f;
  font-size: 14px;
  font-weight: 400;
  line-height: 14px;
  margin-top: 5px;
  padding-right: 20px;
}

.buttonBox {
  button {
    border: none !important;
    outline: none !important;
  }
}