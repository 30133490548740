.captchaContainer {
  font-family: Arial, sans-serif;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  max-width: 565px;
  margin: auto;

  @media (min-width: 768px) {
    width: 565px;
  }
}

.captchaContainerTitle {
  padding: 0;
  margin: 0;
  margin-bottom: 20px;
  @media (max-width: 500px) {
    text-align: center;
  }

  font-family: Roboto;
  font-size: 14px;
  font-weight: 400;
  line-height: 16px;
}

.captchaContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  width: 100%;

  @media (min-width: 500px) {
    flex-direction: row;
  }

  @media (min-width: 768px) {
    gap: 50px;
  }
}

.captchaImage {
  width: 198px;
  height: 92px;
  border: 1px solid #ddd;
  border-radius: 4px;
}

.captchaForm {
  display: flex;
  flex-direction: column;
  padding: 0;
  margin: 0;
  width: 100%;
}

.captchaInput {
  padding: 10px 14px;
  height: 44px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  line-height: 25px;
  width: 100%;
}

.captchaInput:focus {
  outline: #ccc;
}

.captchaContainer .captchaContent .captchaForm .captchaButton {
  padding: 10px;
  font-size: 14px;
  border: none;
  border-radius: 4px;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;
  background-color: #74B566;
  margin-top: 12px;
  width: 100%;
}

.captchaButton:hover {
  background-color: #90C485;
}

// .captchaContainer .captchaContent .captchaForm .captchaButton:hover {
//   background-color: #0056b3;
// }

.verificationResult {
  margin-top: 10px;
  font-size: 16px;
  color: #333;
}

.errorMessage {
  color: #C24040;
  margin-top: 4px;
  font-weight: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}

.errorBox .reloadButton {
  margin-top: 15px !important;
  padding: 10px !important;
  color: white !important;
  border: none !important;
  cursor: pointer !important;
  font-size: 12px !important;
}