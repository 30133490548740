.iframeWrapper {
  position: absolute;
  top: 0;
  left: 0;
  border: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: visible;
  z-index: 99999;
  body {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
