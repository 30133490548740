h2 {
  margin: 0;
  padding: 0;
}

.general_container {
  display: flex;
  justify-content: center;
  margin-bottom: 60px;
  margin-top: 70px;
}
.meterBox {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 24px;
  
  @media (min-width: 768px) {
    margin-bottom: 40px;
  }
}

.output_page_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 715px;
  background-color: #ffffff;
  border-radius: 10px;
  padding: 30px 20px 30px 20px;
  margin: 0 10px;

  @media (min-width: 768px) {
    width: 715px;
    padding: 40px 0px;
  }

  .title_container {
    margin-bottom: 20px;

    font-family: "Roboto";
    font-weight: 500;
    font-size: 20px;
    line-height: 28px;
    text-align: center;
    color: #303134;
    //margin-bottom: 14px;

    @media (min-width: 768px) {
      font-weight: 600;
      font-size: 24px;
      line-height: 34px;
    }
  }

  .header_container {
    display: flex;
    align-items: center;
    margin-bottom: 36px;
    gap: 3px;

    h2 {
      font-family: "Roboto";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      color: #2d3035;
      margin: 0;
    }
  }

  .content_container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    margin-bottom: 20px;

    @media (min-width: 768px) { 
      margin-bottom: 12px;
    }
  }

  .content_container_item {
    display: flex;
    justify-content: center;
    background-color: #c3ccd9;
    border-radius: 5px;

    .analysis_result_container {
      display: flex;
      flex-direction: row;
      width: 352px;
      flex-wrap: wrap;
      margin-bottom: 4px;

      .analysis_result_item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 170px;
        background: #ffffff;
        border-radius: 5px;
        padding: 0;
        margin: 4px 0 0 4px;

        span {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;

          color: #707479;
        }

        p {
          font-family: "Roboto";
          font-style: normal;
          font-weight: 500;
          font-size: 22px;
          color: #303134;
          margin: 4px 0 0 0;
        }
      }
    }
  }

  .chart {
    height: 190px;

    .chart_gauge {
      height: 190px;
      width: 190px;
    }
  }
}

.details {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #717780;
  text-align: center;
  line-height: 22px;
  max-width: 400px;
  margin-bottom: 36px;

  @media (max-width: 768px) { 
    margin-bottom: 20px;
  }
}

.button_container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 36px;
  width: 100%;

  @media (min-width: 768px) { 
    width: auto;
  }

  button {
    background: #ef6f22;
    border-radius: 4px;
    border: none !important;
    outline: none !important;
    font-family: "Roboto";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    color: #ffffff;
    padding: 14px 42px;
    cursor: pointer;
    width: 100%;

    @media (min-width: 768px) { 
      width: auto;
    }

    &:hover {
      opacity: 0.8;
    }
  }
}

.policy {
  font-family: "Roboto";
  font-weight: 400;
  font-size: 14px;
  color: #717780;
  text-align: center;
  line-height: 22px;
  max-width: 350px;

  @media (min-width: 768px) {
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
  }
}

.policyBold {
  font-weight: 500;
  color: #717780;
  font-size: 14px;
  line-height: 22px;

  @media (min-width: 768px) {
    font-weight: 500;
    font-size: 16px;
    line-height: 25px;
  }
}

.policyContainer {
  margin-bottom: 12px;
}

.resultsBox {
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(4, 1fr);
  width: auto;

  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }
}

.resultsBoxItem {
  padding: 8px 20px;
  border: 1px solid #d2dbe8;
  border-radius: 8px;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4px;

  p {
    padding: 0;
    margin: 0;
  }

  .text {
    font-size: 12px;
    font-weight: 400;
    line-height: 14px;
    color: #717780;

    @media (min-width: 768px) {
      font-weight: 400;
      font-size: 14px;
      line-height: 23px;
    }
  }
  
  .value {
    font-size: 14px;
    line-height: 22px;
    font-weight: 500;

    @media (min-width: 768px) {
      font-size: 16px;
      line-height: 24px;
      color: #000000;
    }
  }
}

.resultText {
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: #717780;
}

.resultValue {
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  color: #000000;
}

.btnClose {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;

  @media (min-width: 768px) {
    top: 50px;
    top: 30px;
  }
}
.btnClose:after {
  content: "";
  height: 20px;
  border-left: 2px solid #303134;
  position: absolute;
  transform: rotate(45deg);
}

.btnClose:before {
  content: "";
  height: 20px;
  border-left: 2px solid #303134;
  position: absolute;
  transform: rotate(-45deg);
}