.container {
  box-sizing: border-box;
  border: 1px solid #B3BCC9;
  border-radius: 7px;
  padding: 9px 0;
  color: #2D3035;
  display: flex;
  justify-content: center;

  font-weight: 400;
  font-size: 14px;
  line-height: 16px;

  cursor: pointer;
  transition: border 0.3s ease, background-color 0.3s ease, color 0.3s ease;
}

.container:hover {
  border: 1px solid #196FAF;
}

.activeContainer {
  font-weight: 500;
  border: 1px solid #196FAF;
  background-color: #EDF4F8;
}
