h5 {
  padding: 0;
  margin: 0;
}

.tooltipContainer {
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  color: #155d92; // Default color
  transition: color 0.3s ease;

  &:hover {
    color: #0b324f; // Color on hover
  }
}

.iconActive {
  color: #0b7ec5; // Color when tooltip is active
}

.tooltipText {
  position: absolute;
  margin-left: 30px;
  top: 50%;
  left: 100%; // Розміщення справа від іконки
  transform: translateY(-50%) translateX(15px); // Легке коригування позиції
  background-color: #fff; // Білий фон для тултіпа
  border-radius: 8px; // Закруглені кути
  z-index: 10;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); // Легка тінь для кращої видимості
  width: 100%; // Встановлення ширини в auto для динамічного розміру

  // Стилі заголовка
  .tooltipHeader {
    background-color: #f7f7f7; // Сірий фон для заголовка
    color: #212529; // Темний колір тексту
    padding: 8px 10px;
    border-radius: 8px 8px 0 0; // Закруглені верхні кути
    font-weight: bold; // Жирний шрифт для заголовка
    margin-bottom: 5px; // Пробіл під заголовком
  }

  // Стилі абзаців
  p {
    margin: 0; // Видалення стандартного відступу
    text-align: left; // Вирівнювання тексту вліво
    white-space: nowrap; // Запобігання переносу тексту
    font-size: 16px;
    font-weight: 400;
  }

  // Стрілка з бордером
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    right: 100%; // Вирівнювання до лівого краю тултіпа
    transform: translateY(-50%); // Коригування позиції стрілки
    border-width: 18px; // Розмір стрілки
    border-style: solid;
    border-color: transparent #fff transparent transparent; // Основна стрілка
    z-index: 1; // Вище бордера
  }

  // // Бордер для стрілки
  // &::after {
  //   content: '';
  //   position: absolute;
  //   top: 50%;
  //   right: 100%; // Вирівнювання до лівого краю тултіпа
  //   transform: translateY(-50%); // Коригування позиції стрілки
  //   border-width: 18px; // Такого ж розміру, як у основної стрілки
  //   border-style: solid;
  //   border-color: transparent #cccccc transparent transparent; // Бордер
  //   z-index: 0; // Нижче основної стрілки
  //   margin: -1px 0 0 -1px; // Коригування відступів для перекриття
  // }
}

// Show tooltip on hover or when active
.tooltipContainer:hover .tooltipText,
.tooltipContainer .iconActive + .tooltipText {
  opacity: 1;
  transform: translateY(-50%) translateX(0); // Move tooltip back to default position on hover
}


.upArrow {
  display: inline-block;
  position: relative;
  border: 1px solid #B3BCC9;
  text-decoration: none;
  border-radius: 8px;
  padding: 20px;
  // margin-top: 50px;
  position: absolute;
  // margin-left: 30px;
  top: 30%;
  left: 100%; // Розміщення справа від іконки
  transform: translateY(-48.5%) translateX(10px); // Легке коригування позиції
  background-color: #fff; // Білий фон для тултіпа
  border-radius: 8px; // Закруглені кути
  z-index: 10;
}

.upArrow:before {
  content: '';
  display: block;  
  position: absolute;
  left: -20px; /* Позиціюємо стрілку ліворуч від кнопки */
  top: 50%; /* Центруємо по вертикалі */
  transform: translateY(-50%); /* Вирівнюємо по центру */
  width: 0;
  height: 0;
  border: 10px solid transparent;
  border-right-color: #B3BCC9; /* Змінено на правий колір для стрілки вліво */
}

.upArrow:after {
  content: '';
  display: block;  
  position: absolute;
  left: -18px; /* Центрування по горизонталі */
  top: 50%; /* Центруємо по вертикалі */
  transform: translateY(-50%); /* Вирівню /* Вирівнювання по центру */
  transform: translateY(-50%); /* Вирівнюємо по центру */
  width: 0;
  height: 0;
  border: 9px solid transparent;
  border-right-color: white; 
}

.headerText {
  font-weight: 700;
  font-size: 16px;
  line-height: 18.75px;
}

.contentText {
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}