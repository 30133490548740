.mainBtn {
  background-color: #5796d2;
  border-radius: 4px;
  border: none;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  text-align: center;
  color: #ffffff;
  padding: 14px 42px;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.modal {
  position: absolute;
  z-index: 2000;
}

.overlay {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(22.3%, 58.8%, 82.4%, 0.9);
  z-index: 1000;
  overflow-y: auto;
}

.btnClose {
  width: 20px;
  height: 20px;
  position: absolute;
  top: 30px;
  right: 20px;
  cursor: pointer;
}
.btnClose:after {
  content: "";
  height: 20px;
  border-left: 2px solid #303134;
  position: absolute;
  transform: rotate(45deg);
}

.btnClose:before {
  content: "";
  height: 20px;
  border-left: 2px solid #303134;
  position: absolute;
  transform: rotate(-45deg);
}

@media screen and (max-width: 800px) {
  .mainBtn {
    font-size: 14px;
    padding: 12px 34px;
  }
}

@media screen and (max-width: 556px) {
  .overlay {
    align-items: unset;
  }
}
