body,
html {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500&display=swap");

body {
  font-family: "Roboto", sans-serif;
}

.pac-container {
  z-index: 9999 !important;
}

.pac-container {
  border-radius: 5px;
  box-shadow: 0px 3px 20px 0px rgba(0, 0, 0, 0.15);
  margin-top: 10px;
  background-color: white;
}

.pac-item {
  padding: 5px;
}

.pac-item:not(:first-child) {
  //border-top: 1px solid gray;
}